import { combineReducers } from 'redux';
import imagesReducer, { ImagesState } from './imagesReducer';
import resourcesReducer, { ResourceState } from './resourcesReducer';
import uiReducer, { UiState } from './uiReducer';
import userReducer, { UserState } from './userReducer';

export interface GlobalState {
    user: UserState;
    ui: UiState;
    resources: ResourceState;
    images: ImagesState;
}

const reducer = combineReducers({
    user: userReducer,
    ui: uiReducer,
    resources: resourcesReducer,
    images: imagesReducer,
});

export default reducer;
