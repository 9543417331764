import { Action } from 'redux';
import {
    crudCreateAction,
    crudDeleteAction,
    crudGetListAction,
    crudUpdateAction,
    reqIdAction,
} from '../../redux/actions/fetchActionCreator';
import {
    resolvedMetadataAvatarTypePropertiesActionCreator,
    resolveMetadataAvatarTypeForAvatarCreation,
} from '../../redux/actions/resourceActionCreator';
import { loginAction } from '../../redux/actions/securityActionCreator';

export interface Sort {
    field: string;
    order: string;
}

export interface Pagination {
    page: number;
    perPage: number;
}

export type DataProvider = {
    getList: (resource: string, params: GetListParams) => Promise<GetListResult>;

    getOne: (resource: string, params: GetOneParams) => Promise<GetOneResult>;

    create: (resource: string, params: CreateParams) => Promise<CreateResult>;

    update: (resource: string, params: UpdateParams) => Promise<UpdateResult>;

    delete: (resource: string, params: DeleteParams) => Promise<DeleteResult>;

    [key: string]: any;
};

export interface RequestPayload {
    pagination: Pagination;
    sort: Sort;
    filter: object;
}

export interface GetListParams {
    pagination: Pagination;
    sort: Sort;
    filter: any;
}

export interface GetListResult {
    data: Record[];
    total: number;
}

export interface GetOneParams {
    id: Identifier;
}

export interface Record {
    id: Identifier;
    '@id': string;

    [key: string]: any;
}

export type Identifier = string | number;

export interface GetOneResult {
    data: Record;
}

export interface CreateParams {
    data: any;
}

export interface CreateResult {
    data: Record;
}

export interface UpdateParams {
    id: Identifier;
    data: any;
    previousData: Record;
}

export interface UpdateResult {
    data: Record;
}

export interface DeleteParams {
    id: Identifier;
}

export interface DeleteResult {
    data?: Record;
}

export type AuthProvider = {
    login: (params: any) => Promise<any>;
};

// > Any action structure
export interface ActionStructureAction {
    readonly type: string;
    readonly payload: any;
    readonly meta?: any;
}

export interface ActionStructure {
    action: string;
    fn: (...args: any[]) => ActionStructureAction;
}

// <

// any action using fetcher
export interface ActionStructureFetcher extends ActionStructure {
    action: string;
    fn: (...args: any[]) => any;
}

export interface FetcherRequestAction extends ActionStructureAction {
    readonly type: string;
    readonly payload: any;
    readonly meta: {
        resource: string;
        callbacks?: Callbacks;
        [key: string]: any;
    };
}

export interface GeneralRequestAction {
    readonly type: typeof reqIdAction.action;
    readonly payload: {
        reqId: string;
        action: FetcherRequestAction;
    };
}

export interface SuccessPayload {
    total: number;
    data: any | any[];
}

export interface Callbacks {
    onSuccess?: (payload: SuccessPayload, requestPayload: any) => any | null | Action | Action[];
    onFailure?: (
        payload: {
            err: {
                config?: object;
                request?: object;
                response?: {
                    data: object;
                    status: number;
                };
            };
        },
        requestPayload: object
    ) => any;
    onTerminating?: (payload: object, requestPayload: object, isSuccess: boolean) => any | null | Action | Action[];
}

export interface CrudGetListAction extends FetcherRequestAction {
    readonly type: typeof crudGetListAction.action;
    readonly payload: RequestPayload;
}

export interface ReqIdCrudGetListActionStructureFetcher extends ActionStructureFetcher {
    readonly action: typeof reqIdAction.action;
    fn: (
        reqId: string,
        resource: string,
        pagination: Pagination,
        sort: Sort,
        filter: object,
        callbacks: Callbacks | undefined
    ) => GeneralRequestAction;
}

export interface CrudCreateAction extends FetcherRequestAction {
    readonly type: typeof crudCreateAction.action;
    readonly payload: {
        data: any;
    };
    readonly meta: {
        resource: string;
        callbacks?: Callbacks;
    };
}

export interface ReqIdCrudCreateActionStructureFetcher extends ActionStructureFetcher {
    readonly action: typeof reqIdAction.action;
    fn: (
        reqId: string,
        data: any,
        resource: string,
        callbacks: Callbacks | undefined
    ) => {
        readonly type: typeof reqIdAction.action;
        payload: {
            reqId: string;
            action: CrudCreateAction;
        };
    };
}

export interface CrudUpdateAction extends FetcherRequestAction {
    readonly type: typeof crudUpdateAction.action;
    readonly payload: {
        data: any;
        id: Identifier;
        previousData: any;
    };
    readonly meta: {
        resource: string;
        callbacks?: Callbacks;
    };
}

export interface CrudDeleteAction extends FetcherRequestAction {
    readonly type: typeof crudDeleteAction.action;
    readonly payload: {
        id: Identifier;
    };
    readonly meta: {
        resource: string;
        callbacks?: Callbacks;
    };
}

export interface ReqIdCrudUpdateActionStructureFetcher extends ActionStructureFetcher {
    readonly action: typeof reqIdAction.action;
    fn: (
        reqId: string,
        id: Identifier,
        resource: string,
        data: any,
        previousData: any,
        callbacks: Callbacks | undefined
    ) => {
        readonly type: typeof reqIdAction.action;
        payload: {
            reqId: string;
            action: CrudUpdateAction;
        };
    };
}

export interface ReqIdCrudDeleteActionStructureFetcher extends ActionStructureFetcher {
    readonly action: typeof reqIdAction.action;
    fn: (
        reqId: string,
        id: Identifier,
        resource: string,
        callbacks: Callbacks | undefined
    ) => {
        readonly type: typeof reqIdAction.action;
        payload: {
            reqId: string;
            action: CrudDeleteAction;
        };
    };
}

export interface LoginAction extends FetcherRequestAction {
    readonly type: typeof loginAction.action;
    readonly payload: { username: string; password: string };
}

export enum ReqStatus {
    PENDING = 'PENDING',
    FAILURE = 'FAILURE',
    DONE = 'DONE',
}

export interface MC extends Record {
    id: string;
    idReference: string;
    name: string;
    avatar: string;
    company: string;
    yourls: {
        id: string;
        short: string;
        mcUrl: string;
    };
    creationDate: Date;
    pairedUp: boolean;
    enabled: boolean;
}

export interface Node {
    id: string;
    name: string;
    extraDataClassmapper: string;
    extraData: any;
    dateOfTakesPlace: string;
    iconId: string;
    nextNodes: any[];
    configuration: {
        [key: string]: any;
    };
    nodeTypes: any;
}

export interface CurrentReferencing extends Record {
    standardReferencing: {
        runner: {
            node: Node;
            firstNode: Node;
            [key: string]: any;
        };
        [key: string]: any;
    };
}

export interface MetadataAvatarTypeProperty extends Record {
    id: string;
    name: string;
    values: any[];
    private: boolean;
    definition: string;
    timestamp: number;
    createdAt: Date;
    metadataAvatarType: string;
    isEncrypted: boolean;
    fieldType: string;
    order: number;
    option: string;
    required: boolean;
}

export interface MetadataAvatarType extends Record {
    id: string;
    name: string;
    alphaId: string;
    description: string;
    groupMetadataAvatarType: string;
    editable: boolean;
}

export interface ResolveMetadataAvatarTypeForAvatarCreationAction extends ActionStructureAction {
    type: typeof resolveMetadataAvatarTypeForAvatarCreation.action;
    payload: {
        reqId: string;
        type: string;
    };
}

export interface ResolveMetadataAvatarTypeForAvatarCreation extends ActionStructure {
    action: typeof resolvedMetadataAvatarTypePropertiesActionCreator.action;
    fn: (reqId: string, fullBarCodeIdentification: string) => ResolveMetadataAvatarTypeForAvatarCreationAction;
}

export interface Avatar extends Record {
    id: string;
    name: string;
    mcs: string[];
    metadataAvatarType: {
        groupMetadataAvatarType: string;
        [key: string]: any;
    };
    company?: {
        name?: string;
        identifiers: string[] | null;
        '@id': string;
    };
    postalCode: string;
    alphaId: string;
    [key: string]: any;
    files: any;
    images: any[];
}

export interface TraceabilityNoteDialog {
    open: boolean;
    mode?: string;
    onClose: Function;
    changeTraceability: (data: any, toUpdateAvatar?: string | null, node?: any) => void;
    node:
        | {
              id: string;
              name: string;
              configuration: {
                  customFields: { [key: string]: any };
              };
          }
        | any;
    zoneConfig?: object;
    avatar?: {
        company?: {
            name?: string;
        };
        id?: string;
    };
    isAnonymous?: boolean;
    user?: {
        firstName: string;
        lastName: string;
    };
}

export interface TokenWithRoles {
    token: string;
    refreshToken: string;
}

export interface TypeNote {
    [key: string]: any;
}

export enum GroupMetadataAvatarTypeCustomFieldUrlType {
    GDRIVE = 'gdrive',
}
export interface GroupMetadataAvatarTypeCustomFieldUrl {
    url: string;
    type: GroupMetadataAvatarTypeCustomFieldUrlType;
}

export interface GroupMetadataAvatarType {
    id: string;
    name: string;
    company: string;
    customFields: {
        user_guide?: GroupMetadataAvatarTypeCustomFieldUrl;
        routine_maintenance?: GroupMetadataAvatarTypeCustomFieldUrl;
        technical_sheet?: GroupMetadataAvatarTypeCustomFieldUrl;
    };
}


export interface ExternalUserDataIdentification {
    name: string,
    email: string,
}

export interface SendEmailsProps {
    subject: string,
    addressesTo: string[],
    addressesCC?: string[],
    body: string,
    bodyType?: string,
    attachments?:any,
    addressesReplyTo?:string,
    sendToAdmin?:boolean,
    scMedias?:any,
}