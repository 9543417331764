import { ActionStructure } from '../../services/utils/types';

export const resolvedImage: ActionStructure = {
    action: '360SC/RESOLVED_IMAGE',
    fn: (mediaUri: string, url: string) => ({
        type: resolvedImage.action,
        payload: {
            mediaUri,
            url,
        },
    }),
};
