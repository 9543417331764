import { Identifier, Record } from './types';

export const extractIdFromURI = (URI: string) => {
    const mat = URI.split('/');
    return mat[mat.length - 1];
};

export const getResourceURIFROMAnotherURI = (anotherURI: string, id: Identifier, resource: string) =>
    // @ts-ignore
    `${anotherURI.match('(.*)\\/(\\w+)\\/(\\w+)')[1]}/${resource}/${id}`;

export const uniqueItemInArray = (items: Record[]): any => {
    const obj: { [key: string]: Record } = {};
    items.forEach((item) => {
        obj[item.id] = item;
    });

    return Object.values(obj);
};

export const getResourceURIFROMAnotherURIWithoutId = (anotherURI: string, resource: string) =>
    // @ts-ignore
    `${anotherURI.match('(.*)\\/(\\w+)\\/(\\w+)')[1]}/${resource}`;

export const getUriFromItem = (item: any) => {
    return item && typeof item === 'object' && item.hasOwnProperty('@id') ? item['@id'] : item;
};

export const getUriFromItems = (items: any) => {
    return items && Array.isArray(items) ? items.map((item) => getUriFromItem(item)) : [];
};
