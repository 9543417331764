import axios from 'axios';
import { decodeToken as decodeTokenLib } from 'jsontokens';
// @ts-ignore
import qs from 'qs';
import {
    LOGIN_ANONYMOUS,
    TOKEN_MAX_PARTS_SIZE,
    TOKEN_PARTS_MC_AUTO_AUTH_PREFIX,
    TOKEN_PARTS_MC_AUTO_AUTH_SIZE,
    TOKEN_PARTS_PREFIX,
    TOKEN_PARTS_SIZE,
    UNIQUE_IDENTIFIER_BY_FINGER,
} from './CONST';

const CORE_API = process.env.NEXT_PUBLIC_CORE_API;
const STOCK_COMPANY_ID = process.env.NEXT_PUBLIC_STOCK_COMPANY_ID;
const ASSETS_ROOT = process.env.NEXT_PUBLIC_ASSETS_ROOT;
const assetPrefix = process.env.NEXT_PUBLIC_ASSET_PREFIX;

export const splitToken = (token: string, newArray: string[]) => {
    if (token.length / TOKEN_MAX_PARTS_SIZE > 1) {
        newArray.push(token.slice(0, TOKEN_MAX_PARTS_SIZE));
        splitToken(token.slice(TOKEN_MAX_PARTS_SIZE, token.length), newArray);
    } else {
        newArray.push(token);
    }
};

export const joinTokenFromCookies = (cookies: { [key: string]: any }) => {
    const { [TOKEN_PARTS_MC_AUTO_AUTH_SIZE]: tokenPartsMcAutoAuthSize } = cookies;
    const { [TOKEN_PARTS_SIZE]: tokenPartsSize } = cookies;
    let token = '';

    if (tokenPartsMcAutoAuthSize) {
        for (let i = 0; i < Number(tokenPartsMcAutoAuthSize); i++) {
            token += cookies[`${TOKEN_PARTS_MC_AUTO_AUTH_PREFIX}${i}`];
        }
    } else if (tokenPartsSize) {
        for (let i = 0; i < Number(tokenPartsSize); i++) {
            token += cookies[`${TOKEN_PARTS_PREFIX}${i}`];
        }
    }

    return token;
};

interface AxiosRequestError {
    err: { status: number; statusText: string; data: any };
}

// @ts-ignore
export const getTokenEasy = async (
    redirection_key: string
): Promise<{ data?: { token: string } } | AxiosRequestError> => {
    const tokenResponse = await axios({
        baseURL: `${CORE_API}${LOGIN_ANONYMOUS}`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: {
            _username: redirection_key,
            _password: UNIQUE_IDENTIFIER_BY_FINGER,
        },
        transformRequest: (data) => qs.stringify(data),
    }).catch((err) => ({
        err,
    }));

    if ('err' in tokenResponse) {
        let status: number = 500;
        let statusText: string = 'Error server';
        let data: any = {};

        console.log("GetEasyToken");
        console.log(Object.keys(tokenResponse.err))
        console.log(tokenResponse.err)

        if ('response' in tokenResponse.err) {
            status = 'status' in tokenResponse.err.response ? tokenResponse.err.response.status : '';
            statusText = 'statusText' in tokenResponse.err.response ? tokenResponse.err.response.statusText : '';
            statusText = 'data' in tokenResponse.err.response ? tokenResponse.err.response.data : '';
        }

        return {
            err: {
                status,
                statusText,
                data,
            },
        };
    }

    if (tokenResponse.data && tokenResponse.data.hasOwnProperty('token')) return tokenResponse;

    return {};
};

export const checkTokenAndRefresh = (oldToken: string, newToken: string) => {
    if (!isValidToken(oldToken)) return true;

    const { payload: oldTokenData } = decodeToken(oldToken);
    const { payload: newTokenData } = decodeToken(newToken);

    return (
        !oldTokenData.hasOwnProperty('company_id') ||
        !newTokenData.hasOwnProperty('company_id') ||
        // @ts-ignore
        (oldTokenData['company_id'] !== newTokenData['company_id'] &&
            // @ts-ignore
            newTokenData['company_id'] != STOCK_COMPANY_ID)
    );
};

export const isValidToken = (token: string) => {
    if (!token) return false;

    try {
        const tokenData: any = decodeToken(token);
        const current_time = Date.now() / 1000;
        return tokenData && tokenData.payload.exp >= current_time;
    } catch (e) {
        return false;
    }
};

export const decodeToken = (token: string) => decodeTokenLib(token);

export default isValidToken;
