import { ActionStructureAction } from '../../services/utils/types';
import { resolvedImage } from '../actions/imagesActionCreator';

export interface ImagesState {
    [key: string]: string;
}

const InitialState: ImagesState = {};

export default (state: ImagesState = InitialState, action: ActionStructureAction) => {
    switch (action.type) {
        case resolvedImage.action: {
            const { mediaUri, url } = action.payload;
            return {
                ...state,
                [mediaUri]: url,
            };
        }
        default:
            return state;
    }
};
