import { uniqueItemInArray } from '../../services/utils/ids';
import { decodeToken } from '../../services/utils/token';
import {
    ActionStructureAction,
    Avatar,
    CurrentReferencing,
    GroupMetadataAvatarType,
    MC,
    MetadataAvatarType,
    TokenWithRoles,
    TypeNote,
} from '../../services/utils/types';
import {
    cancelAvatarCreation,
    resolvedAvatarActionCreator,
    resolvedCurrentReferencingActionCreator,
    resolvedGroupMetadataAvatarTypeActionCreator,
    resolvedMCActionCreator,
    resolvedMetadataAvatarTypeActionCreator,
    resolvedMetadataAvatarTypePropertiesActionCreator,
    resolvedTokenWithRolesActionCreator,
    resolvedTypeNotesActionCreator,
    updateBarCodeProductionOrderCreateAvatar,
    updateBarCodeTypeAndObjectNumberCreateAvatar,
} from '../actions/resourceActionCreator';

export interface ResourceState {
    mcs: MC[];
    mcUriFromUrl: string | null;
    metadataAvatarTypes: MetadataAvatarType[];
    createAvatarData: {
        mcUris: string[];
        metadataAvatarTypeUri: string | null;
        serialNumber: string | null;
        productionOrderNumber: string | null;
    };
    avatar: Avatar | null;
    currentReferencing: CurrentReferencing | null;
    tokenWithRoles: TokenWithRoles | null;
    typeNotes: TypeNote[];
    groupMetadataAvatarType: GroupMetadataAvatarType | null;
    metadataAvatarTypeProperties: any[];
    roles: string[];
}

export const InitialState: ResourceState = {
    mcs: [],
    mcUriFromUrl: null,
    metadataAvatarTypes: [],
    createAvatarData: {
        mcUris: [],
        metadataAvatarTypeUri: null,
        serialNumber: null,
        productionOrderNumber: null,
    },
    avatar: null,
    currentReferencing: null,
    tokenWithRoles: null,
    typeNotes: [],
    groupMetadataAvatarType: null,
    metadataAvatarTypeProperties: [],
    roles: [],
};

const reducer = (state: ResourceState = InitialState, action: ActionStructureAction) => {
    switch (action.type) {
        case resolvedMCActionCreator.action: {
            const mcs: MC[] = uniqueItemInArray([...state.mcs, action.payload.mc]);
            return {
                ...state,
                mcs,
                mcUriFromUrl: action.meta.isMCUrl ? action.payload.mc['@id'] : state.mcUriFromUrl,
                createAvatarData: {
                    ...state.createAvatarData,
                    mcUris: [
                        mcs.map((mc: MC): string => {
                            return mc['@id'];
                        }),
                    ],
                },
            };
        }
        case resolvedAvatarActionCreator.action: {
            const { avatar } = action.payload;
            return {
                ...state,
                avatar,
            };
        }
        case resolvedMetadataAvatarTypePropertiesActionCreator.action: {
            const { metadataAvatarTypeProperties } = action.payload;
            return {
                ...state,
                metadataAvatarTypeProperties,
            };
        }
        case resolvedTokenWithRolesActionCreator.action: {
            const { tokenWithRoles } = action.payload;
            const {
                // @ts-ignore
                payload: { roles },
            } = decodeToken(tokenWithRoles.token);
            const keys = Object.keys(roles);
            let arrayRoles: string[] = [];
            keys.map((key) => {
                arrayRoles.push(roles[key]);
            });
            return {
                ...state,
                tokenWithRoles,
                roles: arrayRoles,
            };
        }
        case resolvedTypeNotesActionCreator.action: {
            const { typeNotes } = action.payload;
            return {
                ...state,
                typeNotes,
            };
        }
        case resolvedGroupMetadataAvatarTypeActionCreator.action: {
            const { groupMetadataAvatarType } = action.payload;
            return {
                ...state,
                groupMetadataAvatarType,
            };
        }
        case resolvedMetadataAvatarTypeActionCreator.action: {
            return {
                ...state,
                metadataAvatarTypes: [...state.metadataAvatarTypes, ...action.payload.metadataAvatarTypes],
                createAvatarData: {
                    ...state.createAvatarData,
                    metadataAvatarTypeUri:
                        action.meta.isCreateAvatar && action.payload.metadataAvatarTypes.length === 1
                            ? action.payload.metadataAvatarTypes[0]['@id']
                            : state.createAvatarData.metadataAvatarTypeUri,
                },
            };
        }
        case updateBarCodeTypeAndObjectNumberCreateAvatar.action: {
            return {
                ...state,
                createAvatarData: {
                    ...state.createAvatarData,
                    serialNumber: action.payload.identification,
                },
            };
        }
        case updateBarCodeProductionOrderCreateAvatar.action: {
            return {
                ...state,
                createAvatarData: {
                    ...state.createAvatarData,
                    productionOrderNumber: action.payload.identification,
                },
            };
        }
        case cancelAvatarCreation.action: {
            return {
                ...state,
                metadataAvatarTypes: state.metadataAvatarTypes.filter(
                    (m: MetadataAvatarType) => m['@id'] !== state.createAvatarData.metadataAvatarTypeUri
                ),
                createAvatarData: {
                    productionOrderNumber: null,
                    mcUris: state.createAvatarData.mcUris.filter((uri: string) => uri === state.mcUriFromUrl),
                    metadataAvatarTypeUri: null,
                    serialNumber: null,
                },
            };
        }
        case resolvedCurrentReferencingActionCreator.action: {
            const { currentReferencing } = action.payload;
            return {
                ...state,
                currentReferencing,
            };
        }
        default:
            return state ? state : InitialState;
    }
};

export default reducer;
