const emailBody = "<head>\n" +
    "  <!--[if gte mso 9]>\n" +
    "<xml>\n" +
    "  <o:OfficeDocumentSettings>\n" +
    "    <o:AllowPNG/>\n" +
    "    <o:PixelsPerInch>96</o:PixelsPerInch>\n" +
    "  </o:OfficeDocumentSettings>\n" +
    "</xml>\n" +
    "<![endif]-->\n" +
    "  <meta http-equiv=\"Content-Type\" content=\"text/html; charset=UTF-8\">\n" +
    "  <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">\n" +
    "  <meta name=\"x-apple-disable-message-reformatting\">\n" +
    "  <!--[if !mso]><!-->\n" +
    "  <meta http-equiv=\"X-UA-Compatible\" content=\"IE=edge\">\n" +
    "  <!--<![endif]-->\n" +
    "  <title></title>\n" +
    "\n" +
    "  <style type=\"text/css\">\n" +
    "    @media only screen and (min-width: 620px) {\n" +
    "      .u-row {\n" +
    "        width: 600px !important;\n" +
    "      }\n" +
    "      .u-row .u-col {\n" +
    "        vertical-align: top;\n" +
    "      }\n" +
    "      .u-row .u-col-100 {\n" +
    "        width: 600px !important;\n" +
    "      }\n" +
    "    }\n" +
    "    \n" +
    "    @media (max-width: 620px) {\n" +
    "      .u-row-container {\n" +
    "        max-width: 100% !important;\n" +
    "        padding-left: 0px !important;\n" +
    "        padding-right: 0px !important;\n" +
    "      }\n" +
    "      .u-row .u-col {\n" +
    "        min-width: 320px !important;\n" +
    "        max-width: 100% !important;\n" +
    "        display: block !important;\n" +
    "      }\n" +
    "      .u-row {\n" +
    "        width: calc(100% - 40px) !important;\n" +
    "      }\n" +
    "      .u-col {\n" +
    "        width: 100% !important;\n" +
    "      }\n" +
    "      .u-col>div {\n" +
    "        margin: 0 auto;\n" +
    "      }\n" +
    "    }\n" +
    "    \n" +
    "    body {\n" +
    "      margin: 0;\n" +
    "      padding: 0;\n" +
    "    }\n" +
    "    \n" +
    "    table,\n" +
    "    tr,\n" +
    "    td {\n" +
    "      vertical-align: top;\n" +
    "      border-collapse: collapse;\n" +
    "    }\n" +
    "    \n" +
    "    p {\n" +
    "      margin: 0;\n" +
    "    }\n" +
    "    \n" +
    "    .ie-container table,\n" +
    "    .mso-container table {\n" +
    "      table-layout: fixed;\n" +
    "    }\n" +
    "    \n" +
    "    * {\n" +
    "      line-height: inherit;\n" +
    "    }\n" +
    "    \n" +
    "    a[x-apple-data-detectors='true'] {\n" +
    "      color: inherit !important;\n" +
    "      text-decoration: none !important;\n" +
    "    }\n" +
    "    \n" +
    "    table,\n" +
    "    td {\n" +
    "      color: #000000;\n" +
    "    }\n" +
    "  </style>\n" +
    "\n" +
    "\n" +
    "\n" +
    "  <!--[if !mso]><!-->\n" +
    "  <link href=\"https://fonts.googleapis.com/css?family=Cabin:400,700\" rel=\"stylesheet\" type=\"text/css\">\n" +
    "  <!--<![endif]-->\n" +
    "\n" +
    "</head>\n" +
    "\n" +
    "<body class=\"clean-body u_body\" style=\"margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #f9f9f9;color: #000000\">\n" +
    "  <!--[if IE]><div class=\"ie-container\"><![endif]-->\n" +
    "  <!--[if mso]><div class=\"mso-container\"><![endif]-->\n" +
    "  <table style=\"border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: #f9f9f9;width:100%\" cellpadding=\"0\" cellspacing=\"0\">\n" +
    "    <tbody>\n" +
    "      <tr style=\"vertical-align: top\">\n" +
    "        <td style=\"word-break: break-word;border-collapse: collapse !important;vertical-align: top\">\n" +
    "          <!--[if (mso)|(IE)]><table width=\"100%\" cellpadding=\"0\" cellspacing=\"0\" border=\"0\"><tr><td align=\"center\" style=\"background-color: #f9f9f9;\"><![endif]-->\n" +
    "\n" +
    "\n" +
    "          <div class=\"u-row-container\" style=\"padding: 0px;background-color: transparent\">\n" +
    "            <div class=\"u-row\" style=\"Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;\">\n" +
    "              <div style=\"border-collapse: collapse;display: table;width: 100%;background-color: transparent;\">\n" +
    "                <!--[if (mso)|(IE)]><table width=\"100%\" cellpadding=\"0\" cellspacing=\"0\" border=\"0\"><tr><td style=\"padding: 0px;background-color: transparent;\" align=\"center\"><table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" style=\"width:600px;\"><tr style=\"background-color: transparent;\"><![endif]-->\n" +
    "\n" +
    "                <!--[if (mso)|(IE)]><td align=\"center\" width=\"600\" style=\"width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;\" valign=\"top\"><![endif]-->\n" +
    "                <div class=\"u-col u-col-100\" style=\"max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;\">\n" +
    "                  <div style=\"width: 100% !important;\">\n" +
    "                    <!--[if (!mso)&(!IE)]><!-->\n" +
    "                    <div style=\"padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;\">\n" +
    "                      <!--<![endif]-->\n" +
    "\n" +
    "                      <!--[if (!mso)&(!IE)]><!-->\n" +
    "                    </div>\n" +
    "                    <!--<![endif]-->\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <!--[if (mso)|(IE)]></td><![endif]-->\n" +
    "                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "          <div class=\"u-row-container\" style=\"padding: 0px;background-color: transparent\">\n" +
    "            <div class=\"u-row\" style=\"Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;\">\n" +
    "              <div style=\"border-collapse: collapse;display: table;width: 100%;background-color: transparent;\">\n" +
    "                <!--[if (mso)|(IE)]><table width=\"100%\" cellpadding=\"0\" cellspacing=\"0\" border=\"0\"><tr><td style=\"padding: 0px;background-color: transparent;\" align=\"center\"><table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" style=\"width:600px;\"><tr style=\"background-color: #ffffff;\"><![endif]-->\n" +
    "\n" +
    "                <!--[if (mso)|(IE)]><td align=\"center\" width=\"600\" style=\"width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;\" valign=\"top\"><![endif]-->\n" +
    "                <div class=\"u-col u-col-100\" style=\"max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;\">\n" +
    "                  <div style=\"width: 100% !important;\">\n" +
    "                    <!--[if (!mso)&(!IE)]><!-->\n" +
    "                    <div style=\"padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;\">\n" +
    "                      <!--<![endif]-->\n" +
    "\n" +
    "                      <table style=\"font-family:'Cabin',sans-serif;\" role=\"presentation\" cellpadding=\"0\" cellspacing=\"0\" width=\"100%\" border=\"0\">\n" +
    "                        <tbody>\n" +
    "                          <tr>\n" +
    "                            <td style=\"overflow-wrap:break-word;word-break:break-word;padding:20px;font-family:'Cabin',sans-serif;\" align=\"left\">\n" +
    "\n" +
    "                              <table width=\"100%\" cellpadding=\"0\" cellspacing=\"0\" border=\"0\">\n" +
    "                                <tr>\n" +
    "                                  <td style=\"padding-right: 0px;padding-left: 0px;\" align=\"center\">\n" +
    "                                  </td>\n" +
    "                                </tr>\n" +
    "                              </table>\n" +
    "\n" +
    "                            </td>\n" +
    "                          </tr>\n" +
    "                        </tbody>\n" +
    "                      </table>\n" +
    "\n" +
    "                      <!--[if (!mso)&(!IE)]><!-->\n" +
    "                    </div>\n" +
    "                    <!--<![endif]-->\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <!--[if (mso)|(IE)]></td><![endif]-->\n" +
    "                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "          <div class=\"u-row-container\" style=\"padding: 0px;background-color: transparent\">\n" +
    "            <div class=\"u-row\" style=\"Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #003399;\">\n" +
    "              <div style=\"border-collapse: collapse;display: table;width: 100%;background-color: transparent;\">\n" +
    "                <!--[if (mso)|(IE)]><table width=\"100%\" cellpadding=\"0\" cellspacing=\"0\" border=\"0\"><tr><td style=\"padding: 0px;background-color: transparent;\" align=\"center\"><table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" style=\"width:600px;\"><tr style=\"background-color: #003399;\"><![endif]-->\n" +
    "\n" +
    "                <!--[if (mso)|(IE)]><td align=\"center\" width=\"600\" style=\"width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;\" valign=\"top\"><![endif]-->\n" +
    "                <div class=\"u-col u-col-100\" style=\"max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;\">\n" +
    "                  <div style=\"width: 100% !important;\">\n" +
    "                    <!--[if (!mso)&(!IE)]><!-->\n" +
    "                    <div style=\"padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;\">\n" +
    "                      <!--<![endif]-->\n" +
    "\n" +
    "                      <table style=\"font-family:'Cabin',sans-serif;\" role=\"presentation\" cellpadding=\"0\" cellspacing=\"0\" width=\"100%\" border=\"0\">\n" +
    "                        <tbody>\n" +
    "                          <tr>\n" +
    "                            <td style=\"overflow-wrap:break-word;word-break:break-word;padding:40px 10px 10px;font-family:'Cabin',sans-serif;\" align=\"left\">\n" +
    "\n" +
    "                              <table width=\"100%\" cellpadding=\"0\" cellspacing=\"0\" border=\"0\">\n" +
    "                                <tr>\n" +
    "                                  <td style=\"padding-right: 0px;padding-left: 0px;\" align=\"center\">\n" +
    "\n" +
    "\n" +
    "                                  </td>\n" +
    "                                </tr>\n" +
    "                              </table>\n" +
    "\n" +
    "                            </td>\n" +
    "                          </tr>\n" +
    "                        </tbody>\n" +
    "                      </table>\n" +
    "\n" +
    "                      <table style=\"font-family:'Cabin',sans-serif;\" role=\"presentation\" cellpadding=\"0\" cellspacing=\"0\" width=\"100%\" border=\"0\">\n" +
    "                        <tbody>\n" +
    "                          <tr>\n" +
    "                            <td style=\"overflow-wrap:break-word;word-break:break-word;padding:0px 10px 31px;font-family:'Cabin',sans-serif;\" align=\"left\">\n" +
    "\n" +
    "                              <div style=\"color: #e5eaf5; line-height: 140%; text-align: center; word-wrap: break-word;\">\n" +
    "                                <p style=\"font-size: 14px; line-height: 140%; text-align: center;\"><span style=\"font-size: 28px; line-height: 39.2px;\"><strong><span style=\"line-height: 39.2px; font-size: 28px;\">{greeting} {name}, {welcomeMessage}</span></strong>\n" +
    "                                  </span>\n" +
    "                                </p>\n" +
    "                              </div>\n" +
    "\n" +
    "                            </td>\n" +
    "                          </tr>\n" +
    "                        </tbody>\n" +
    "                      </table>\n" +
    "\n" +
    "                      <!--[if (!mso)&(!IE)]><!-->\n" +
    "                    </div>\n" +
    "                    <!--<![endif]-->\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <!--[if (mso)|(IE)]></td><![endif]-->\n" +
    "                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "          <div class=\"u-row-container\" style=\"padding: 0px;background-color: transparent\">\n" +
    "            <div class=\"u-row\" style=\"Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;\">\n" +
    "              <div style=\"border-collapse: collapse;display: table;width: 100%;background-color: transparent;\">\n" +
    "                <!--[if (mso)|(IE)]><table width=\"100%\" cellpadding=\"0\" cellspacing=\"0\" border=\"0\"><tr><td style=\"padding: 0px;background-color: transparent;\" align=\"center\"><table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" style=\"width:600px;\"><tr style=\"background-color: #ffffff;\"><![endif]-->\n" +
    "\n" +
    "                <!--[if (mso)|(IE)]><td align=\"center\" width=\"600\" style=\"width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;\" valign=\"top\"><![endif]-->\n" +
    "                <div class=\"u-col u-col-100\" style=\"max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;\">\n" +
    "                  <div style=\"width: 100% !important;\">\n" +
    "                    <!--[if (!mso)&(!IE)]><!-->\n" +
    "                    <div style=\"padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;\">\n" +
    "                      <!--<![endif]-->\n" +
    "\n" +
    "                      <table style=\"font-family:'Cabin',sans-serif;\" role=\"presentation\" cellpadding=\"0\" cellspacing=\"0\" width=\"100%\" border=\"0\">\n" +
    "                        <tbody>\n" +
    "                          <tr>\n" +
    "                            <td style=\"overflow-wrap:break-word;word-break:break-word;padding:33px 55px;font-family:'Cabin',sans-serif;\" align=\"left\">\n" +
    "\n" +
    "                              <div style=\"line-height: 160%; text-align: center; word-wrap: break-word;\">\n" +
    "                                <p style=\"font-size: 14px; line-height: 160%;\"><span style=\"font-size: 22px; line-height: 35.2px;\">{enterCodeMessage} <strong>{codeValidation}</strong>, {enterCodeMessage1}. </span></p>\n" +
    "                                <p style=\"font-size: 14px; line-height: 160%;\"><span style=\"font-size: 22px; line-height: 35.2px;\">{enterCodeMessage2} {currentNodeName}</span><span style=\"font-size: 22px; line-height: 35.2px;\">.</span></p>\n" +
    "                                <p style=\"font-size: 14px; line-height: 160%;\">&nbsp;</p>\n" +
    "                                <p style=\"font-size: 14px; line-height: 160%;\">&nbsp;</p>\n" +
    "                              </div>\n" +
    "\n" +
    "                            </td>\n" +
    "                          </tr>\n" +
    "                        </tbody>\n" +
    "                      </table>\n" +
    "\n" +
    "                      <table style=\"font-family:'Cabin',sans-serif;\" role=\"presentation\" cellpadding=\"0\" cellspacing=\"0\" width=\"100%\" border=\"0\">\n" +
    "                        <tbody>\n" +
    "                          <tr>\n" +
    "                            <td style=\"overflow-wrap:break-word;word-break:break-word;padding:33px 55px 60px;font-family:'Cabin',sans-serif;\" align=\"left\">\n" +
    "\n" +
    "                              <div style=\"line-height: 160%; text-align: center; word-wrap: break-word;\">\n" +
    "                                <p style=\"line-height: 160%; font-size: 14px;\"><span style=\"font-size: 18px; line-height: 28.8px;\">{gratefulnessMessage},</span></p>\n" +
    "                                <p style=\"line-height: 160%; font-size: 14px;\"><span style=\"font-size: 18px; line-height: 28.8px;\">{teamMessage}</span></p>\n" +
    "                              </div>\n" +
    "\n" +
    "                            </td>\n" +
    "                          </tr>\n" +
    "                        </tbody>\n" +
    "                      </table>\n" +
    "\n" +
    "                      <!--[if (!mso)&(!IE)]><!-->\n" +
    "                    </div>\n" +
    "                    <!--<![endif]-->\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <!--[if (mso)|(IE)]></td><![endif]-->\n" +
    "                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "          <div class=\"u-row-container\" style=\"padding: 0px;background-color: transparent\">\n" +
    "            <div class=\"u-row\" style=\"Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #e5eaf5;\">\n" +
    "              <div style=\"border-collapse: collapse;display: table;width: 100%;background-color: transparent;\">\n" +
    "                <!--[if (mso)|(IE)]><table width=\"100%\" cellpadding=\"0\" cellspacing=\"0\" border=\"0\"><tr><td style=\"padding: 0px;background-color: transparent;\" align=\"center\"><table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" style=\"width:600px;\"><tr style=\"background-color: #e5eaf5;\"><![endif]-->\n" +
    "\n" +
    "                <!--[if (mso)|(IE)]><td align=\"center\" width=\"600\" style=\"width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;\" valign=\"top\"><![endif]-->\n" +
    "                <div class=\"u-col u-col-100\" style=\"max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;\">\n" +
    "                  <div style=\"width: 100% !important;\">\n" +
    "                    <!--[if (!mso)&(!IE)]><!-->\n" +
    "                    <div style=\"padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;\">\n" +
    "                      <!--<![endif]-->\n" +
    "\n" +
    "                      <!--[if (!mso)&(!IE)]><!-->\n" +
    "                    </div>\n" +
    "                    <!--<![endif]-->\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <!--[if (mso)|(IE)]></td><![endif]-->\n" +
    "                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "          <div class=\"u-row-container\" style=\"padding: 0px;background-color: transparent\">\n" +
    "            <div class=\"u-row\" style=\"Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #003399;\">\n" +
    "              <div style=\"border-collapse: collapse;display: table;width: 100%;background-color: transparent;\">\n" +
    "                <!--[if (mso)|(IE)]><table width=\"100%\" cellpadding=\"0\" cellspacing=\"0\" border=\"0\"><tr><td style=\"padding: 0px;background-color: transparent;\" align=\"center\"><table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" style=\"width:600px;\"><tr style=\"background-color: #003399;\"><![endif]-->\n" +
    "\n" +
    "                <!--[if (mso)|(IE)]><td align=\"center\" width=\"600\" style=\"width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;\" valign=\"top\"><![endif]-->\n" +
    "                <div class=\"u-col u-col-100\" style=\"max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;\">\n" +
    "                  <div style=\"width: 100% !important;\">\n" +
    "                    <!--[if (!mso)&(!IE)]><!-->\n" +
    "                    <div style=\"padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;\">\n" +
    "                      <!--<![endif]-->\n" +
    "\n" +
    "                      <!--[if (!mso)&(!IE)]><!-->\n" +
    "                    </div>\n" +
    "                    <!--<![endif]-->\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <!--[if (mso)|(IE)]></td><![endif]-->\n" +
    "                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "\n" +
    "          <!--[if (mso)|(IE)]></td></tr></table><![endif]-->\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "    </tbody>\n" +
    "  </table>\n" +
    "  <!--[if mso]></div><![endif]-->\n" +
    "  <!--[if IE]></div><![endif]-->\n" +
    "</body>\n";



export const getEmailTextWithCorrectValues = (valuesKeyToChange = {}) => {
    const keys = Object.keys(valuesKeyToChange);
    let newEmailBody = '';
    let oldEmail = emailBody;

    const arrayValues = Object.values(valuesKeyToChange);
    let regex = null;
    arrayValues.forEach((value: any, pos) => {
        newEmailBody = oldEmail.replace(`{${keys[pos]}}`, value)
        oldEmail = newEmailBody;
    });

    return newEmailBody;
}