import { all, put, takeEvery } from 'redux-saga/effects';
import {} from 'redux-saga';
import {
    resolvedMetadataAvatarTypeActionCreator,
    resolveMetadataAvatarTypeForAvatarCreation,
} from '../actions/resourceActionCreator';
import {
    MetadataAvatarType,
    MetadataAvatarTypeProperty,
    ResolveMetadataAvatarTypeForAvatarCreationAction,
} from '../../services/utils/types';
import { crudGetListReqIdAction, crudGetOneReqIdAction } from '../actions/fetchActionCreator';
import { METADATA_AVATAR_TYPE_PROPERTIES, METADATA_AVATAR_TYPES } from '../../services/utils/CONST';
import { Action } from 'redux';
import getRequestIdGenerator from '../../services/requestIdGenerator';
import { enqueueSnackbar } from '../actions/uiActionCreator';

export const handleResolveMetadataAvatarTypeForAvatarCreation = function* (
    action: ResolveMetadataAvatarTypeForAvatarCreationAction
) {
    yield put(
        crudGetListReqIdAction.fn(
            action.payload.reqId,
            METADATA_AVATAR_TYPE_PROPERTIES,
            {
                page: 1,
                perPage: 10,
            },
            {
                field: 'id',
                order: 'asc',
            },
            {
                'values[0_exprVar]': '$[*]',
                'values[0_searchType]': 'search',
                'values[0_data]': action.payload.type,
                'values[0_searchTypeOneOrAll]': 'one',
                name: process.env.NEXT_PUBLIC_METADATA_AVATAR_TYPE_PROPERTY_NAME_SEARCH_IDENTIFIER,
            },
            {
                onSuccess: (payload: {
                    total: number;
                    data: MetadataAvatarTypeProperty[];
                }): Action | null | Action[] => {
                    if (payload.total > 1 || payload.total === 0) {
                        return null;
                    }

                    return [
                        enqueueSnackbar.fn({
                            id: getRequestIdGenerator().gen(),
                            message: 'common.messages.searching-metadata-avatar-type',
                            translateMessage: true,
                            option: {
                                variant: 'info',
                                autoHideDuration: 2000,
                            },
                        }),
                        crudGetOneReqIdAction.fn(
                            getRequestIdGenerator().gen(),
                            METADATA_AVATAR_TYPES,
                            payload.data[0].metadataAvatarType,
                            {
                                onSuccess: (payload: { data: MetadataAvatarType }) => {
                                    return resolvedMetadataAvatarTypeActionCreator.fn([payload.data], {
                                        isCreateAvatar: true,
                                    });
                                },
                                onFailure: (payload: any) => {
                                    console.error(payload);
                                },
                            }
                        ),
                    ];
                },
            }
        )
    );
};

export default () =>
    function* () {
        yield all([
            takeEvery(
                resolveMetadataAvatarTypeForAvatarCreation.action,
                handleResolveMetadataAvatarTypeForAvatarCreation
            ),
        ]);
    };
