import { enqueueSnackbar, removeEnqueueSnackbar, resolvedQuery, showErrorPageAction } from '../actions/uiActionCreator';
import { OptionsObject, SnackbarMessage } from 'notistack';
import { ActionStructureAction } from '../../services/utils/types';

export interface EnqueueSnackbar {
    id: string;
    translateMessage: boolean;
    message: SnackbarMessage;
    option?: OptionsObject;
}

export interface UiState {
    url: {
        query: {
            redirectionKey: string | null;
        };
    };
    enqueueSnackbar: EnqueueSnackbar[];
    errorPage: {
        title: string;
        statusCode: number;
    } | null;
}

const InitialState: UiState = {
    url: {
        query: {
            redirectionKey: null,
        },
    },
    enqueueSnackbar: [],
    errorPage: null,
};

export default (state: UiState = InitialState, action: ActionStructureAction) => {
    switch (action.type) {
        case resolvedQuery.action: {
            return {
                ...state,
                url: {
                    ...state.url,
                    query: {
                        ...state.url.query,
                        ...action.payload.query,
                    },
                },
            };
        }
        case enqueueSnackbar.action: {
            return {
                ...state,
                enqueueSnackbar: [...state.enqueueSnackbar, action.payload.item],
            };
        }
        case removeEnqueueSnackbar.action: {
            return {
                ...state,
                enqueueSnackbar: [...state.enqueueSnackbar.filter((item) => item.id !== action.payload.id)],
            };
        }

        case showErrorPageAction.action: {
            const { title, statusCode } = action.payload;
            return {
                ...state,
                errorPage: {
                    title,
                    statusCode,
                },
            };
        }
        default:
            return state;
    }
};
