import axios from "axios";
import {
    CreateParams,
    CreateResult,
    DataProvider,
    DeleteParams,
    DeleteResult,
    GetListParams,
    GetListResult,
    GetOneParams, GetOneResult,
    UpdateParams,
    UpdateResult
} from "../utils/types";
// @ts-ignore
import qs from "qs";
import { AUTHENTICATION_TYPE } from "../utils/CONST";
import {extractIdFromURI} from "../utils/ids";

export class URLMapperResource {
    private defaultURL;
    private defaultURI;

    private mappingURL: {
        [key: string]: string[];
    };
    private reverseMappingURL: {
        [key: string]: string;
    };

    private mappingURI: {
        [key: string]: string[];
    };
    private reverseMappingURI: {
        [key: string]: string;
    };

    constructor(defaultURL: string, defaultURI: string) {
        this.defaultURL = defaultURL;
        this.defaultURI = defaultURI;

        this.mappingURL = {};
        this.mappingURI = {};
        this.reverseMappingURL = {};
        this.reverseMappingURI = {};
    }

    isResourceAndGetURL = (resource: string) => {
        if (this.reverseMappingURL && this.reverseMappingURL.hasOwnProperty(resource))
            return this.reverseMappingURL[resource];

        return this.defaultURL;
    };

    isResourceAndGetURI = (resource: string) => {
        if (this.reverseMappingURI && this.reverseMappingURI.hasOwnProperty(resource)) return this.reverseMappingURI[resource];

        return this.defaultURI;
    };
}

class WrapperProvider {
    private provider: DataProvider|null = null;
    private config: DataProviderConfig;

    constructor(config: DataProviderConfig) {
        this.config = config;
    }

    getProvider(): DataProvider {
        if (this.provider === null) this.configureProvider();

        // @ts-ignore
        return this.provider;
    }

    configureProvider = () => {
        this.provider = {
            getList: (resource: string, params: GetListParams): Promise<GetListResult> => {
                const baseURL = this.config?.urlMappingResource.isResourceAndGetURL(resource);
                const { pagination, filter, sort } = params;

                const filters = {
                    itemsPerPage: pagination.perPage, // ApiPlatform perPage filter
                    ...pagination,
                    ...filter
                }

                if (sort && sort.field && sort.order) {
                    filters[`order[${sort.field}]`] = sort.order;
                }

                return axios({
                    baseURL,
                    url: `/${resource}`,
                    headers: this.config?.getHeaders(),
                    paramsSerializer: (params) => qs.stringify(params),
                    params: {
                        ...filters
                    }
                })
                    .then((response) => {
                        return response.data;
                    })
                    .then((dataResponse) => {
                        const hydraMembers = dataResponse && dataResponse.hasOwnProperty("hydra:member") ? dataResponse["hydra:member"] : [];
                        const data = hydraMembers.map((hydraMember: any) => {
                          return {
                            ...hydraMember,
                          };
                        });

                        const total = dataResponse && dataResponse.hasOwnProperty("hydra:totalItems") ? dataResponse["hydra:totalItems"] : 0;

                        return {
                            data,
                            total
                        };
                    })
            },

            getOne: (resource: string, params: GetOneParams): Promise<GetOneResult> => {
                const baseURL = this.config?.urlMappingResource.isResourceAndGetURI(resource);

                return axios({
                    baseURL,
                    url: `${params.id}`,
                    headers: this.config?.getHeaders()
                }).then(response => response.data).then(data => ({
                    data
                }));
            },

            create: (resource: string, params: CreateParams): Promise<CreateResult> => {
                const baseURL = this.config?.urlMappingResource.isResourceAndGetURL(resource);

                return axios({
                    baseURL,
                    url: `/${resource}`,
                    method: "POST",
                    headers: this.config?.getHeaders(),
                    data: {
                        ...params.data
                    },
                }).then(response => response.data).then(data => ({ data }));
            },

            update: (resource: string, params: UpdateParams): Promise<UpdateResult> => {
                const baseURL = this.config?.urlMappingResource.isResourceAndGetURI(resource);
                return axios({
                    baseURL,
                    url: `${params.id}`,
                    method: "PUT",
                    headers: this.config?.getHeaders(),
                    data: {
                        ...params.data
                    }
                }).then(response => response.data).then(data => ({ data }));
            },

            delete: (resource: string, params: DeleteParams): Promise<DeleteResult> => {
                const baseURL = this.config?.urlMappingResource.isResourceAndGetURI(resource);

                return axios({
                    baseURL,
                    url: `${params.id}`,
                    method: "DELETE",
                    headers: this.config?.getHeaders(),
                    transformResponse: [
                        function(data) {
                            // @ts-ignore
                            if (!data || (data && !data.id)) return { id: extractIdFromURI(params.id) };
                            return data;
                        }
                    ]
                });
            }
        };
    };

    public updateFnGeTokenAndUpdateHeaders = (getToken: () => string) => {
        const headers = this.config ? this.config.getHeaders() : {};
        this.config.getHeaders = (): object => {
            return {
                ...headers,
                Authorization: `${AUTHENTICATION_TYPE} ${getToken()}`
            };
        };
    };
}

export interface DataProviderConfig {
    getHeaders(): object;
    urlMappingResource: URLMapperResource;
}

let wrapperProvider: WrapperProvider|null = null;

export const getWrapperProvider = (config: DataProviderConfig) => {
    if (wrapperProvider === null) wrapperProvider = new WrapperProvider(config);

    return wrapperProvider;
};
