import { all, put, takeEvery } from 'redux-saga/effects';
import {
    sendEmailValidationActionCreator,
    SendEmailValidationActionCreatorAction
} from '../actions/emailActionCreator';
import { getEmailTextWithCorrectValues } from '../../components/email/validation-code-email';
import getRequestIdGenerator from '../../services/requestIdGenerator';
import { crudCreateReqIdAction } from '../actions/fetchActionCreator';
import { SEND_EMAILS } from '../../services/utils/CONST';
import { SendEmailsProps } from '../../services/utils/types';

export const handleSendEmailValidationCreation = function* (
    action: SendEmailValidationActionCreatorAction
) {

    const emailText = getEmailTextWithCorrectValues({
        name: action.payload.name,
        codeValidation: action.payload.codeValidation,
        currentNodeName: action.payload.currentState,
        email: action.payload.email,
        ...(action.payload.dataTranslated ? action.payload.dataTranslated : {})
    });
    const htmlTextEncoded = btoa(unescape(encodeURIComponent(emailText)));

    const dataToSend: SendEmailsProps = {
        subject: action.payload.subjectEmail,
        body: htmlTextEncoded,
        addressesTo: [action.payload.email],
        sendToAdmin: false
    }
    yield put(
        crudCreateReqIdAction.fn(
            getRequestIdGenerator().gen(),
            dataToSend,
            SEND_EMAILS,
            {
                onSuccess: ({ data }: { data: any }) => {
                    action.meta.onSuccess(data);
                },
                onFailure: (error: any) => {
                    action.meta.onFailure(error);
                    console.error("SEND EMAIL with this data", dataToSend, "error", error)
                }
            }
        )
    );
};

export default () =>
    function* () {
        yield all([
            takeEvery(
                sendEmailValidationActionCreator.action,
                handleSendEmailValidationCreation
            ),
        ]);
    };
