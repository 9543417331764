import { createTheme, withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';

export const BACKGROUND_COLOR = '#e4e5eb';
export const PRIMARY_COLOR = '#3f51b5';
export const SECONDARY_COLOR = '#C4D600';

const mainTheme = createTheme({
    palette: {
        primary: {
            main: PRIMARY_COLOR,
        },
        secondary: {
            main: SECONDARY_COLOR,
        },
    },
});

export const ListItemTextPrimaryStyled = withStyles({
    root: {
        color: PRIMARY_COLOR,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
})(ListItemText);

export default mainTheme;
