import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { NextIntlProvider } from 'next-intl';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import HeadConf from '../components/head-conf/head-conf';
import { GlobalState } from '../redux/reducers';
import { wrapper } from '../redux/store';
import '../styles/globals.css';
import mainTheme from '../themes/main-theme';
import { getErrorPageData } from '../redux/selectors/errorPage';
import Error from 'next/error';

const MyApp = ({ Component, pageProps }: AppProps) => {
    const errorPageData: { title: string; statusCode: number } | null = useSelector((state: GlobalState) =>
        getErrorPageData(state)
    );

    useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            // @ts-ignore
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    return (
        <ThemeProvider theme={mainTheme}>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                autoHideDuration={2000}
            >
                <NextIntlProvider messages={pageProps.messages}>
                    <CssBaseline />
                    <HeadConf title='Rikutec' />
                    {errorPageData ? (
                        <Error statusCode={errorPageData.statusCode} title={errorPageData.title} />
                    ) : (
                        <Component {...pageProps} />
                    )}
                </NextIntlProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
};

export default wrapper.withRedux(MyApp);

const pushAnalytic = (metrics: NextWebVitalsMetric) => () => {
    const { id, name, label, value } = metrics;
    // @ts-ignore
    window.gtag('event', name, {
        event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
        value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
        event_label: id, // id unique to current page load
        non_interaction: true, // avoids affecting bounce rate.
    });
};

export function reportWebVitals(metrics: NextWebVitalsMetric) {
    const pushData = pushAnalytic(metrics);

    const interval = setInterval(() => {
        // @ts-ignore
        if (window && window.gtag) {
            pushData();
            clearInterval(interval);
        }
    }, 1000);
}
