import {
    ActionStructure,
    Avatar,
    CurrentReferencing,
    GroupMetadataAvatarType,
    MC,
    MetadataAvatarType,
    MetadataAvatarTypeProperty,
    Record,
    ResolveMetadataAvatarTypeForAvatarCreation,
    ResolveMetadataAvatarTypeForAvatarCreationAction,
    TokenWithRoles,
    TypeNote,
} from '../../services/utils/types';

export const resolvedMCActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_MC',
    fn: (
        mc: MC,
        { isMCUrl, isCreateAvatar }: { isMCUrl: boolean; isCreateAvatar: boolean } = {
            isMCUrl: false,
            isCreateAvatar: false,
        }
    ) => ({
        type: resolvedMCActionCreator.action,
        payload: {
            mc,
        },
        meta: {
            isMCUrl,
            isCreateAvatar,
        },
    }),
};

export const resolvedAvatarActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_AVATAR',
    fn: (avatar: Avatar) => ({
        type: resolvedAvatarActionCreator.action,
        payload: {
            avatar,
        },
    }),
};

export const resolvedTokenWithRolesActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_TOKEN_WITH_ROLES',
    fn: (tokenWithRoles: TokenWithRoles) => ({
        type: resolvedTokenWithRolesActionCreator.action,
        payload: {
            tokenWithRoles,
        },
    }),
};

export const resolvedTypeNotesActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_TYPE_NOTES',
    fn: (typeNotes: TypeNote[]) => ({
        type: resolvedTypeNotesActionCreator.action,
        payload: {
            typeNotes,
        },
    }),
};

export const resolvedGroupMetadataAvatarTypeActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_GROUP_METADATA_AVATAR_TYPE',
    fn: (groupMetadataAvatarType: GroupMetadataAvatarType) => ({
        type: resolvedGroupMetadataAvatarTypeActionCreator.action,
        payload: {
            groupMetadataAvatarType,
        },
    }),
};

export const resolvedCurrentReferencingActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_CURRENT_REFERENCING',
    fn: (currentReferencing: CurrentReferencing) => ({
        type: resolvedCurrentReferencingActionCreator.action,
        payload: {
            currentReferencing,
        },
    }),
};

export const resolvedMetadataAvatarTypePropertiesActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_METADATA_AVATAR_TYPE_PROPERTIES',
    fn: (metadataAvatarTypeProperties: MetadataAvatarTypeProperty[]) => ({
        type: resolvedMetadataAvatarTypePropertiesActionCreator.action,
        payload: {
            metadataAvatarTypeProperties,
        },
    }),
};

export const resolvedMetadataAvatarTypeActionCreator: ActionStructure = {
    action: '360SC/RESOLVED_METADATA_AVATAR_TYPE',
    fn: (
        metadataAvatarTypes: MetadataAvatarType[],
        { isCreateAvatar }: { isCreateAvatar: boolean } = { isCreateAvatar: false }
    ) => ({
        type: resolvedMetadataAvatarTypeActionCreator.action,
        payload: {
            metadataAvatarTypes,
        },
        meta: {
            isCreateAvatar,
        },
    }),
};

export const resolveMetadataAvatarTypeForAvatarCreation: ResolveMetadataAvatarTypeForAvatarCreation = {
    action: '360SC/RESOLVE_METADATA_AVATAR_TYPE_FOR_AVATAR_CREATION',
    fn: (reqId: string, type: string): ResolveMetadataAvatarTypeForAvatarCreationAction => ({
        type: resolveMetadataAvatarTypeForAvatarCreation.action,
        payload: {
            reqId,
            type,
        },
    }),
};

export const updateBarCodeTypeAndObjectNumberCreateAvatar: ActionStructure = {
    action: '360SC/CREATE_AVATAR_BARCODE_TYPE_AND_OBJECT_NUMBER',
    fn: (type: string, objectNumber: string) => ({
        type: updateBarCodeTypeAndObjectNumberCreateAvatar.action,
        payload: {
            type,
            objectNumber,
        },
    }),
};

export const updateBarCodeProductionOrderCreateAvatar: ActionStructure = {
    action: '360SC/CREATE_AVATAR_BARCODE_PRODUCTION_ORDER',
    fn: (identification: string) => ({
        type: updateBarCodeProductionOrderCreateAvatar.action,
        payload: {
            identification,
        },
    }),
};

export const cancelAvatarCreation: ActionStructure = {
    action: '360SC/CANCEL_AVATAR_CREATION',
    fn: () => ({
        type: cancelAvatarCreation.action,
        payload: {},
    }),
};
