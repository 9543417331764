
export interface SendEmailValidationActionCreator {
    action: string,
    fn: (subjectEmail: string, name: string, email: string, codeValidation: string, onSuccess: (response: any) => void, onFailure: (error: any) => void, currentState?: string, dataTranslated?: any) => SendEmailValidationActionCreatorAction
}

export interface SendEmailValidationActionCreatorAction {
    type: typeof sendEmailValidationActionCreator.action,
    payload: {
        name: string,
        subjectEmail: string,
        email: string,
        currentState?: string,
        codeValidation: string,
        dataTranslated?: {
            greeting: string,
            welcomeMessage: string,
            enterCodeMessage: string,
            enterCodeMessage1: string,
            enterCodeMessage2: string,
            gratefulnessMessage: string,
            teamMessage: string,
        },
    },
    meta: {
        onSuccess: (response: any) => void,
        onFailure: (error: any) => void,
    }
}

export const sendEmailValidationActionCreator: SendEmailValidationActionCreator = {
    action: '360SC/SEND_EMAIL_VALIDATION',
    fn: (subjectEmail: string, name: string, email: string, codeValidation: string, onSuccess: (response: any) => void, onFailure: (error: any) => void, currentState?: string, dataTranslated?: any): SendEmailValidationActionCreatorAction => ({
        type: sendEmailValidationActionCreator.action,
        payload: {
            subjectEmail, name, email, currentState, codeValidation, dataTranslated
        },
        meta: {
            onFailure,
            onSuccess
        }
    })
};


