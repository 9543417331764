import { ActionStructure } from '../../services/utils/types';

export const registerExternalIdentification: ActionStructure = {
    action: '360SC/REGISTER_EXTERNAL_IDENTIFICATION',
    fn: (userInformation: { name: string; email: string } | null) => ({
        type: registerExternalIdentification.action,
        payload: {
            userInformation,
        },
    }),
};


export const registerCodeValidation: ActionStructure = {
    action: '360SC/REGISTER_CODE_VALIDATION',
    fn: ( code: string ) => ({
        type: registerCodeValidation.action,
        payload: {
            code,
        },
    }),
};
